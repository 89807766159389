<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const showPsw = ref(false);

const form = useForm({
    username: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('auth.signin'), {
        onFinish: () => form.reset('password'),
    });
}
</script>

<template>
    <GuestLayout title="Log in">
        <div class="grid grid-cols-1 md:grid-cols-2 flex-1">
            <div class="flex-col hidden md:flex items-center justify-center bg-gray-100 text-blue-500 text-center">
                <h1 class="text-5xl font-bold mb-4">Welcome Back</h1>
                <p class="text-lg text-gray-700 font-medium mb-8 max-w-lg">
                    Access your account to manage your business efficiently with our POS solution.
                    Stay in control of your transactions, sales, and inventory with ease.
                </p>
            </div>
            <AuthenticationCard>
                <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                    {{ status }}
                </div>
                <form @submit.prevent="submit">
                    <h2 class="text-xl md:text-3xl text-gray-800 font-semibold w-full text-center mb-4">Login</h2>
                    <div>
                        <InputLabel for="username" value="Username" />
                        <TextInput
                            id="username"
                            v-model="form.username"
                            type="text"
                            class="mt-1 block w-full"
                            required
                            autofocus
                            autocomplete="username"
                        />
                        <InputError class="mt-2" :message="form.errors.username" />
                    </div>
                    <div class="mt-4">
                        <InputLabel for="password" value="Password" />
                        <TextInput
                            id="password"
                            v-model="form.password"
                            :type="showPsw ? 'text' : 'password'"
                            class="mt-1 block w-full"
                            required
                            autocomplete="current-password"
                        />
                        <InputError class="mt-2" :message="form.errors.password" />
                        <label class="flex items-center justify-end mt-1">
                            <Checkbox v-model="showPsw" />
                            <span class="ml-1 text-sm text-gray-600">Show Password</span>
                        </label>
                    </div>
                    <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox v-model:checked="form.remember" name="remember" />
                            <span class="ms-2 text-sm text-gray-600">Remember me</span>
                        </label>
                    </div>
                    <div class="flex flex-col-reverse items-center gap-y-2 justify-center mt-4">
                        <Link :href="route('password.request')" class="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Forgot your password?
                        </Link>
                        <Link :href="route('auth.show.signup')" class="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Create an account
                        </Link>
                        <PrimaryButton class="ms-4 w-1/2 justify-center" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            {{ form.processing ? 'Loging in' : 'Login' }}
                        </PrimaryButton>
                    </div>
                </form>
            </AuthenticationCard>
        </div>
</GuestLayout>
</template>
